import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { forgotPassword, clearErrors, authSelector } from "./authSlice";
import { Form, Button, Input, message, Grid, Row, Col } from "antd";
import { MailOutlined } from "@ant-design/icons";
import styles from "./Auth.module.css";
import { Header } from "./Header";

export function ForgotPassword() {
  const { passwordForgot, loading, errors } = useSelector(authSelector);
  const dispatch = useDispatch();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  useEffect(() => {
    if (passwordForgot) {
      message.success(
        "Check your email for instructions on resetting your password"
      );
    }
  }, [passwordForgot]);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error(errors[0]);
      dispatch(clearErrors());
    }
  }, [errors]);

  const handleSubmit = (values) => {
    dispatch(forgotPassword(values));
  };

  return (
    <>
      <div className={styles.loginContainer}>
        <Header />
        <Row
          align="middle"
          className={screens.lg ? "h-100v" : "mt-100"}
          justify="center"
          // gutter={[16, 16]}
        >
          <Col span={24}>
            <Form
              name="normal_login"
              className={styles.loginForm}
              size="large"
              onFinish={handleSubmit}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your Email!",
                  },
                ]}
              >
                <Input
                  prefix={<MailOutlined className="prefixIcon" />}
                  placeholder="Email"
                  type="email"
                />
              </Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="w-100"
                loading={loading}
              >
                Send Password Reset Instructions
              </Button>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
}
