import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/auth/authSlice";
import screeningReducer from "./features/screening/screeningSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    screening: screeningReducer,
  },
});
