import {
  LockOutlined,
  MailOutlined,
  PhoneOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Grid, Input, message, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import quote from "../../assets/img/quote.png";
import styles from "./Auth.module.css";
import { authSelector, clearErrors, signup } from "./authSlice";
import { Header } from "./Header";

export function SignUp() {
  const { currentUser, loading, errors } = useSelector(authSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  useEffect(() => {
    if (currentUser.id) {
      history.push("/");
    }
  }, [currentUser, history]);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error(errors[0]);
      dispatch(clearErrors());
    }
  }, [errors, dispatch]);

  const handleSubmit = (values) => {
    values.phone = "+" + values.country_code + values.phone;
    delete values.country_code;
    dispatch(signup(values));
  };

  return (
    <>
      <div className={styles.loginContainer}>
        <Header />
        <Row
          align="middle"
          className={screens.lg ? "h-100v" : "mt-50"}
          justify="center"
          // gutter={[16, 16]}
        >
          <Col xs={24} sm={24} md={24} lg={4}></Col>
          <Col xs={24} sm={24} md={24} lg={9} className="ta-c">
            <img
              src={quote}
              alt="Welcome quote"
              className={screens.lg ? styles.quote : styles.quoteSm}
            />
          </Col>
          {/* <Col span={2}></Col> */}
          <Col xs={24} sm={24} md={24} lg={7} className="ta-c">
            <Form
              name="normal_login"
              className={styles.loginForm}
              size="large"
              onFinish={handleSubmit}
            >
              {/* <Form.Item className="text-center">
                <img src={quote} alt="Welcome quote" className="w-100 mb-10" />
              </Form.Item> */}
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input your Name!",
                  },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="prefixIcon" />}
                  placeholder="Name"
                />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your Email!",
                  },
                ]}
              >
                <Input
                  prefix={<MailOutlined className="prefixIcon" />}
                  placeholder="Email"
                  type="email"
                />
              </Form.Item>
              <Row gutter={8}>
                <Col span={8}>
                  <Form.Item
                    name="country_code"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input
                      prefix={<PlusOutlined className="prefixIcon" />}
                      placeholder="Code"
                    />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item
                    name="phone"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Input
                      prefix={<PhoneOutlined className="prefixIcon" />}
                      placeholder="Phone"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="prefixIcon" />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>

              <Button
                type="primary"
                htmlType="submit"
                className="w-100"
                loading={loading}
              >
                Let's Begin
              </Button>
              <div className="text-center mt-15">
                <div className="text-white">
                  Already have an account with us?
                </div>
                <div>
                  <a href="/sign_in">
                    <b>Login Here</b>
                  </a>
                </div>
              </div>
            </Form>
          </Col>
          <Col xs={24} sm={24} md={24} lg={4}></Col>
        </Row>
      </div>
    </>
  );
}
