import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import API from "../../api";

const initialState = {
  data: {},
  report: {},
  loading: false,
  errors: null,
};

export const fetchStartup = createAsyncThunk(
  "startups/fetchStartup",
  async (_, thunk) => {
    try {
      let response = await API.startups.get();
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const fetchStartupReport = createAsyncThunk(
  "startups/fetchStartupReport",
  async (id, thunk) => {
    try {
      let response = await API.startups.report(id);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const createStartup = createAsyncThunk(
  "startups/createStartup",
  async (data, thunk) => {
    try {
      let response = await API.startups.create(data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const updateStartup = createAsyncThunk(
  "startups/updateStartup",
  async (data, thunk) => {
    try {
      let response = await API.startups.update(data.id, data.data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const updateStartupDoc = createAsyncThunk(
  "startups/updateStartupDoc",
  async (data, thunk) => {
    try {
      let response = await API.startups.updateDoc(data.id, data.data);
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

export const screeningSlice = createSlice({
  name: "screening",
  initialState,
  extraReducers: {
    [fetchStartup.pending]: (state) => {
      state.loading = true;
    },
    [fetchStartup.fulfilled]: (state, action) => {
      state.loading = false;
      state.data =
        action.payload && action.payload.length > 0 ? action.payload[0] : {};
    },
    [fetchStartup.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },
    [fetchStartupReport.pending]: (state) => {
      state.loading = true;
    },
    [fetchStartupReport.fulfilled]: (state, action) => {
      state.loading = false;
      state.report = action.payload;
    },
    [fetchStartupReport.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },
    [createStartup.pending]: (state) => {
      state.loading = true;
    },
    [createStartup.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [createStartup.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },
    [updateStartup.pending]: (state) => {
      state.loading = true;
    },
    [updateStartup.fulfilled]: (state, action) => {
      state.loading = false;
      state.data = action.payload;
    },
    [updateStartup.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },
    [updateStartupDoc.pending]: (state) => {
      state.loading = true;
    },
    [updateStartupDoc.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateStartupDoc.rejected]: (state, action) => {
      state.loading = false;
      state.errors = action.payload.errors;
    },
  },
});

export const screeningSelector = (state) => state.screening;

export default screeningSlice.reducer;
