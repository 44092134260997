import { Button, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { questions } from "./questions";
import styles from "./Screening.module.css";

const modelsArray = ["B2B", "B2C", "B2B2C", "B2B and B2C", "Other"];

const marketArray = [
  "USD 1 Billion plus Market - Globally/ or in the regions associated with a startup",
  "USD 10 Billion plus Market - Globally/ or in the regions associated with a startup",
  "USD 500 Million plus Market - Globally/ or in the regions associated with a startup",
  "Less than 500 Million but with a clear need of the product",
  "Less than 500 Million but highly competitve space",
  "Highly Competitive but a large market",
  "Niche global market",
  "Niche local market",
];

const stagesArray = [
  "We have recurring revenues",
  "We have customers/users from different geographies",
  "Secured paid clients & have early revenues",
  "Paid POC/Paid users in place",
  "POC/User adoption in place",
  "Minimum Viable product ready",
  "Idea Stage - under development",
];

const targetsArray = [
  "Revenue greater than 1M",
  "Revenue greater than 500k but less than 1M",
  "Revenue greater than 100k but less than 500k",
  "Revenue has kicked off but less than 100k",
  "Paid POC/Pilot going on",
  "Pre Revenue",
];

export function Potential(props) {
  const [showOther, setShowOther] = useState(false);
  const [otherVal, setOtherVal] = useState("");

  useEffect(() => {
    if (props.data.info.biz_model) {
      if (modelsArray.includes(props.data.info.biz_model)) {
        if (props.data.info.biz_model === "Other") {
          setShowOther("true");
        }
      } else {
        setShowOther("true");
        setOtherVal(props.data.info.biz_model);
      }
    }
  }, [props.data.info.biz_model]);

  const handleSubmit = (values) => {
    if (showOther) {
      values["biz_model"] = otherVal.length > 0 ? otherVal : "Other";
    }
    props.onNext({
      info: values,
    });
  };

  const initOther = (data, val) => {
    if (val) {
      return data.includes(val) ? val : "Other";
    } else {
      return val;
    }
  };

  return (
    <Form
      layout="vertical"
      size="large"
      initialValues={{
        market: props.data?.info?.market,
        biz_model: initOther(modelsArray, props.data?.info?.biz_model),
        rev_current: props.data?.info?.rev_current,
        rev_target: props.data?.info?.rev_target,
        prod_stage: props.data?.info?.prod_stage,
      }}
      onFinish={handleSubmit}
    >
      <Form.Item
        name="market"
        label={questions["market"]}
        className="mt-30"
        rules={[
          {
            required: true,
            message: "This field cannot be left empty!",
          },
        ]}
      >
        <Select>
          {marketArray.map((c) => (
            <Select.Option key={c}>{c}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="biz_model"
        label={questions["biz_model"]}
        className={`mt-30 ${showOther ? "mb-10i" : ""}`}
        rules={[
          {
            required: true,
            message: "This field cannot be left empty!",
          },
        ]}
      >
        <Select
          onSelect={(val) => {
            if (val !== "Other") {
              setShowOther(false);
              setOtherVal("");
            } else {
              setShowOther(true);
            }
          }}
        >
          {modelsArray.map((c) => (
            <Select.Option key={c}>{c}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      {showOther && (
        <div className="mb-25">
          <Input
            placeholder="Type your answer..."
            value={otherVal}
            onChange={(e) => setOtherVal(e.target.value)}
          />
        </div>
      )}

      <Form.Item
        name="prod_stage"
        label={questions["prod_stage"]}
        className="mt-30"
        rules={[
          {
            required: true,
            message: "This field cannot be left empty!",
          },
        ]}
      >
        <Select>
          {stagesArray.map((c) => (
            <Select.Option key={c}>{c}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="rev_current"
        label={questions["rev_current"]}
        className="mt-30"
        rules={[
          {
            required: true,
            message: "This field cannot be left empty!",
          },
        ]}
      >
        <Select>
          {targetsArray.map((c) => (
            <Select.Option key={c}>{c}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item className="mb-5">
        <div className={styles.buttons}>
          <div className={styles.left}>
            <Button
              type="default"
              size="default"
              onClick={() => props.onBack()}
            >
              Back
            </Button>
          </div>
          <div className={styles.right}>
            <Button type="primary" size="default" htmlType="submit">
              Next
            </Button>
          </div>
        </div>
      </Form.Item>
    </Form>
  );
}
