export const questions = {
  name: "What's the name of your company?",
  web: "Do you guys have a website?",
  countries: "What country/countries is your company based out of?",
  founded: "When was your company founded?",
  category: "What industry would you say best describes your company?",
  anchor_pitch: "What problem are you solving and how? (Your Anchor Pitch)",
  f_team_makeup: "How does your founding team look like?",
  ceo_exp: "What is the average work experience of founders?",
  ceo_exp_rel:
    "Out of the above 'average experience of the founders', how many years is domain specific experience in the industry where your company is operating?",
  fl_time: "How many full time members does the company have?",
  unfair: "What's your biggest unfair advantage over other startups?",
  approach: "What motivated you to start this company?",
  market:
    "Can you explain the target addressable market size for product/service?",
  biz_model: "What business model are you in?",
  prod_stage: "What is the stage of your product evolution?",
  rev_current:
    "How much revenue was secured by the company in last 12 months? (in USD)",
  inv_stage: "What investment stage is your startup at?",
  accelerator: "Any recognised notable investors/accelerators/incubators?",
  esg_practice:
    "Are you associated/contributing to below industries or using some of those practices in your company?",
  esg_goal:
    "Which of those Sustainable Development Goals are you developing towards?",
  awards: "Any media mentions/awards or any significant recognition?",
  referral: "Where did you hear about us?",
};
