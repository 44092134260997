import React from "react";
import { Grid } from "antd";
import styles from "./Auth.module.css";
import swirl from "../../assets/img/swirl.png";
import logoDark from "../../assets/img/logo_dark.png";
import logo from "../../assets/img/logo.png";
import { useHistory } from "react-router-dom";

export function Header() {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const history = useHistory();

  return (
    <>
      {screens.lg ? (
        <div>
          <img src={swirl} alt="Swirl" className={styles.swirl} />
          <img
            src={logoDark}
            alt="Logo"
            className={styles.logo}
            onClick={() => history.push("/sign_in")}
          />
        </div>
      ) : (
        <div className="w-100 ta-c pt-25">
          <img
            src={logo}
            alt="Logo"
            width="220"
            className="pointer"
            onClick={() => history.push("/sign_in")}
          />
        </div>
      )}
    </>
  );
}
