import React from "react";
import { Switch, Route } from "react-router-dom";
import { ForgotPassword } from "./features/auth/ForgotPassword";
import { Login } from "./features/auth/Login";
import { ResetPassword } from "./features/auth/ResetPassword";
import { SignUp } from "./features/auth/SignUp";
import { Nav } from "./features/nav/Nav";
import { NotFound } from "./NotFound";

export const RootRouter = () => (
  <Switch>
    <Route exact path="/sign_in" component={Login} />
    <Route exact path="/sign_up" component={SignUp} />
    <Route exact path="/forgot_password" component={ForgotPassword} />
    <Route exact path="/reset_password" component={ResetPassword} />
    <Route exact path="/" component={Nav} />
    <Route exact path="/screening" component={Nav} />
    <Route exact path="/profile" component={Nav} />
    <Route component={NotFound} />
  </Switch>
);
