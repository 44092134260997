import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { login, clearErrors, authSelector } from "./authSlice";
import { useHistory } from "react-router-dom";
import { Form, Button, Input, message, Divider, Grid, Row, Col } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import styles from "./Auth.module.css";
import { Header } from "./Header";

export function Login() {
  const { currentUser, loading, errors } = useSelector(authSelector);
  const dispatch = useDispatch();
  const history = useHistory();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  useEffect(() => {
    if (currentUser.id) {
      history.push("/screening");
    }
  }, [currentUser]);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error(errors[0]);
      dispatch(clearErrors());
    }
  }, [errors]);

  const handleSubmit = (values) => {
    dispatch(login(values));
  };

  return (
    <>
      <div className={styles.loginContainer}>
        <Header />
        <Row
          align="middle"
          className={screens.lg ? "h-100v" : "mt-100"}
          justify="center"
          // gutter={[16, 16]}
        >
          <Col span={24}>
            <Form
              name="normal_login"
              className={styles.loginForm}
              size="large"
              onFinish={handleSubmit}
            >
              {/* <Form.Item className="text-center">
          <img src={logo} alt="logo" width="250" className="pb-10" />
            </Form.Item>
            <br /> */}
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please input your Email!",
                  },
                ]}
              >
                <Input
                  prefix={<MailOutlined className="prefixIcon" />}
                  placeholder="Email"
                  type="email"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="prefixIcon" />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>

              <Button
                type="primary"
                htmlType="submit"
                className="w-100"
                loading={loading}
              >
                Login
              </Button>
              <div className="text-center mt-15">
                <Button
                  type="link"
                  size="small"
                  onClick={() => history.push("/forgot_password")}
                >
                  Forgot Password?
                </Button>
              </div>
              <Divider>
                <span className="divider-text">OR</span>
              </Divider>
              <Button
                type="primary"
                ghost
                className="w-100 mt-15"
                onClick={() => history.push("/sign_up")}
              >
                Create New Account
              </Button>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
}
