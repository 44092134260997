import React from "react";
import { Alert, Typography, Button } from "antd";
import { Percentile } from "./Percentile";
import styles from "./Screening.module.css";

const { Text, Title } = Typography;

export function Report(props) {
  return (
    <>
      {props.data.approved ? (
        <Alert
          message="Congratulations!"
          description="You have been approved to be part of Fasttrack! You can login to our fasttrack dashboard and create your profile. Login using the same email/password that you registered with here!"
          type="success"
          action={
            <Button size="small" type="primary" className={styles.ftBtn}>
              Go to Fasttrack
            </Button>
          }
        />
      ) : (
        <Alert
          message="Thank you for you submission!"
          description="Our team will get back to you within 3 business days regarding your approval to Fasttrack. Meanwhile, you can take a look at how your startup stacks up against our previous cohorts."
          type="warning"
        />
      )}
      <Title
        level={3}
        className="text-center mt-30"
        style={{ marginBottom: "3px" }}
      >
        Screening Diagnostic Report
      </Title>
      <div className="text-center mb-20">
        <Text type="secondary">
          ({props.startup.name}'s Percentile Rank vs past Fasttrack cohorts)
        </Text>
      </div>
      <div className="container-md mt-20">
        <Percentile
          data={props.data?.screening}
          insights={props.data?.insights}
        />
      </div>
    </>
  );
}
