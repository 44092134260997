import { Button, Form, Input, Select } from "antd";
import React from "react";
import { countries } from "../../countries";
import { questions } from "./questions";
import styles from "./Screening.module.css";

const { TextArea } = Input;

const categories = [
  "AdTech",
  "AgriTech",
  "CleanTech",
  "Construction Tech",
  "ConsumerTech",
  "CyberSecurity",
  "EduTech",
  "Enterprise Tech",
  "FinTech",
  "FoodTech",
  "Gaming",
  "HealthTech",
  "HR Tech",
  "IoT",
  "Life Sciences",
  "Logistics Tech",
  "Manufacturing Tech",
  "Marketing Tech",
  "Media & Entertainment",
  "Mobility Tech",
  "Retail Tech",
  "Robotics",
  "Sports Tech",
  "Travel Tech",
  "Other",
];

export function Intro(props) {
  const handleSubmit = (values) => {
    props.onNext({
      name: values.name,
      countries: values.countries,
      anchor_pitch: values.anchor_pitch,
      category: values.category,
      info: { web: values.web },
    });
  };

  return (
    <Form
      layout="vertical"
      size="large"
      initialValues={{
        name: props.data.name,
        countries: props.data.countries,
        anchor_pitch: props.data.anchor_pitch,
        category: props.data.category,
        web: props.data?.info?.web,
      }}
      onFinish={handleSubmit}
    >
      <Form.Item
        name="name"
        label={questions["name"]}
        rules={[
          {
            required: true,
            message: "This field cannot be left empty!",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item name="web" label={questions["web"]} className="mt-30">
        <Input placeholder="https://" />
      </Form.Item>

      <Form.Item
        name="countries"
        label={questions["countries"]}
        className="mt-30"
        rules={[
          {
            required: true,
            message: "This field cannot be left empty!",
          },
        ]}
      >
        <Select showSearch mode="multiple">
          {countries.map((c) => (
            <Select.Option key={c}>{c}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="category"
        label={questions["category"]}
        className="mt-30"
        rules={[
          {
            required: true,
            message: "This field cannot be left empty!",
          },
        ]}
      >
        <Select>
          {categories.map((c) => (
            <Select.Option key={c}>{c}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="anchor_pitch"
        label={questions["anchor_pitch"]}
        rules={[
          {
            required: true,
            message: "This field cannot be left empty!",
          },
        ]}
      >
        <TextArea
          rows={3}
          maxLength={350}
          placeholder="Example: Airbnb helps homeowners rent out rooms to travelers online, disrupting the $427 billion hotel industry without needing to own a single property."
        />
      </Form.Item>

      <Form.Item className="mb-5">
        <div className={styles.buttons}>
          <div className={styles.left}>
            <Button
              type="default"
              size="default"
              onClick={() => props.onBack()}
            >
              Back
            </Button>
          </div>
          <div className={styles.right}>
            <Button type="primary" size="default" htmlType="submit">
              Next
            </Button>
          </div>
        </div>
      </Form.Item>
    </Form>
  );
}
