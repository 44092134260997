import React from "react";
import { Form, Select, Button } from "antd";
import styles from "./Screening.module.css";
import { questions } from "./questions";

const foundedArray = [
  "5 years or greater",
  "4 years or greater but less than 5",
  "3 years or greater but less than 4",
  "2 years or greater but less than 3",
  "1 year or greater but less than 2",
  "Less than 1 year or Setting up the company",
  "Will incorporate it in next 6 months",
  "Not Incorporated yet",
];

const foundersArray = [
  "2 or more Full Time Founders with Strategic Advisors",
  "2 or more Full Time Founders",
  "1 Full Time Founder with Strategic Advisors",
  "1 Full Time Founder with a Part Time Co-founder",
  "1 Full time Founder",
  ">1 part-time founders",
];

const ceoArray = [
  "10 years+",
  "7 years+",
  "5 years+",
  "2 years+",
  "Just graduated",
];

const relArray = [
  "10 years+",
  "7 years+",
  "5 years+",
  "2 years+",
  "New Domain for me",
];

export function Team(props) {
  const handleSubmit = (values) => {
    props.onNext({
      info: values,
    });
  };

  return (
    <Form
      layout="vertical"
      size="large"
      initialValues={{
        founded: props.data?.info?.founded,
        f_team_makeup: props.data?.info?.f_team_makeup,
        ceo_exp: props.data?.info?.ceo_exp,
        ceo_exp_rel: props.data?.info?.ceo_exp_rel,
      }}
      onFinish={handleSubmit}
    >
      <Form.Item
        name="founded"
        label={questions["founded"]}
        rules={[
          {
            required: true,
            message: "This field cannot be left empty!",
          },
        ]}
      >
        <Select>
          {foundedArray.map((c) => (
            <Select.Option key={c}>{c}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="f_team_makeup"
        label={questions["f_team_makeup"]}
        className="mt-30"
        rules={[
          {
            required: true,
            message: "This field cannot be left empty!",
          },
        ]}
      >
        <Select>
          {foundersArray.map((c) => (
            <Select.Option key={c}>{c}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="ceo_exp"
        label={questions["ceo_exp"]}
        className="mt-30"
        rules={[
          {
            required: true,
            message: "This field cannot be left empty!",
          },
        ]}
      >
        <Select>
          {ceoArray.map((c) => (
            <Select.Option key={c}>{c}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="ceo_exp_rel"
        label={questions["ceo_exp_rel"]}
        className="mt-30"
        rules={[
          {
            required: true,
            message: "This field cannot be left empty!",
          },
        ]}
      >
        <Select>
          {relArray.map((c) => (
            <Select.Option key={c}>{c}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item className="mb-5">
        <div className={styles.buttons}>
          <div className={styles.left}>
            <Button
              type="default"
              size="default"
              onClick={() => props.onBack()}
            >
              Back
            </Button>
          </div>
          <div className={styles.right}>
            <Button type="primary" size="default" htmlType="submit">
              Next
            </Button>
          </div>
        </div>
      </Form.Item>
    </Form>
  );
}
