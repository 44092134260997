import React, { useEffect } from "react";
import { Layout, Menu, Row, Col, Space, Dropdown, Avatar, Grid } from "antd";
import { FormOutlined, UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { validate, logout, authSelector } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styles from "./Nav.module.css";
import logo from "../../assets/img/logo.png";
import logoSm from "../../assets/img/logo_nav.png";
import { Screening } from "../screening/Screening";
import { Profile } from "../profile/Profile";

export function Nav() {
  const { currentUser } = useSelector(authSelector);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { Header, Content } = Layout;

  useEffect(() => {
    dispatch(validate());
  }, []);

  useEffect(() => {
    if (location.pathname === "/") {
      history.push("/screening");
    }
  }, [location]);

  const getContent = () => {
    switch (location.pathname) {
      case "/profile":
        return <Profile />;
      default:
        return <Screening />;
    }
  };

  const userMenu = (
    <Menu>
      <Menu.Item
        className={styles.navItem}
        onClick={() => history.push("/profile")}
      >
        <UserOutlined className={styles.icon} />
        Profile
      </Menu.Item>
      <Menu.Item className={styles.navItem} onClick={() => dispatch(logout())}>
        <LogoutOutlined className={styles.icon} />
        Logout
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <Layout className={styles.layout}>
        <Header className={styles.header} style={{ padding: 0 }}>
          <Row>
            <Col span={4}>
              <div className={styles.logo}>
                <img
                  src={screens.lg ? logo : logoSm}
                  height="30"
                  alt="logo"
                  className="pointer"
                  onClick={() => history.push("/startups")}
                />
              </div>
            </Col>
            <Col span={16}>
              <Menu
                className={styles.nav}
                selectedKeys={location.pathname.split("/")[1]}
                mode="horizontal"
                onSelect={(data) => history.push(`/${data.key}`)}
              >
                <Menu.Item key="screening">
                  <FormOutlined className={styles.icon} />
                  {screens.lg && "Startup Screening"}
                </Menu.Item>
              </Menu>
            </Col>
            <Col span={4} className={styles.avatar}>
              <Space size="large" className="mr-20">
                <Dropdown overlay={userMenu}>
                  <Avatar
                    size="large"
                    src={currentUser.avatar_url}
                    className="pointer"
                  />
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </Header>
        <Layout
          className={screens.lg ? styles.mainContent : styles.mainContentSm}
        >
          <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
            {getContent()}
          </Content>
        </Layout>
      </Layout>
    </>
  );
}
