import { Button, Form, Input, Select } from "antd";
import React from "react";
import { questions } from "./questions";
import styles from "./Screening.module.css";

const { TextArea } = Input;

const stagesArray = [
  "Series A and above",
  "Pre - Series A",
  "Seed Funded",
  "Angel Funded",
  "Associated with an Accelerator or Incubator",
  "Secured some Grants",
  "Friends and Family",
  "Bootstrapping",
];

const esgPracticesArray = [
  "Stem Cells",
  "Adult entertainment",
  "Alcohol/Tobacco",
  "Any illegal drugs",
  "Weapons and arms",
  "Gambling",
  "GMOs",
  "Military Contracting",
  "Pollutant",
  "Coal/Oil",
  "Animal testing",
  "Gender sexuality or race-based promotion or used in any selection",
  "None of the above",
];

const esgGoalsArray = [
  "Affordable and Clean Energy",
  "Responsible Consumption and Production",
  "Climate Action",
  "Life Below Water",
  "Life On Land",
  "Other",
];

export function Investment(props) {
  const handleSubmit = (values) => {
    props.onNext({
      info: values,
    });
  };

  return (
    <Form
      layout="vertical"
      size="large"
      initialValues={{
        inv_stage: props.data?.info?.inv_stage,
        accelerator: props.data?.info?.accelerator,
        esg_practice: props.data?.info?.esg_practice,
        esg_goal: props.data?.info?.esg_goal,
        awards: props.data?.info?.awards,
      }}
      onFinish={handleSubmit}
    >
      <Form.Item
        name="inv_stage"
        label={questions["inv_stage"]}
        className="mt-30"
        rules={[
          {
            required: true,
            message: "This field cannot be left empty!",
          },
        ]}
      >
        <Select>
          {stagesArray.map((c) => (
            <Select.Option key={c}>{c}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="accelerator" label={questions["accelerator"]}>
        <TextArea
          rows={3}
          placeholder="Example: Y Combinator, SOSV, Techstars, 500 startups or some notable investors"
        />
      </Form.Item>

      <Form.Item
        name="esg_practice"
        label={questions["esg_practice"]}
        className="mt-30"
        rules={[
          {
            required: true,
            message: "This field cannot be left empty!",
          },
        ]}
      >
        <Select>
          {esgPracticesArray.map((c) => (
            <Select.Option key={c}>{c}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="esg_goal"
        label={questions["esg_goal"]}
        className="mt-30"
        rules={[
          {
            required: true,
            message: "This field cannot be left empty!",
          },
        ]}
      >
        <Select>
          {esgGoalsArray.map((c) => (
            <Select.Option key={c}>{c}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item name="awards" label={questions["awards"]}>
        <TextArea
          rows={3}
          placeholder="Feel free to share links of any published media or announcement of significant recognition"
        />
      </Form.Item>

      <Form.Item className="mb-5">
        <div className={styles.buttons}>
          <div className={styles.left}>
            <Button
              type="default"
              size="default"
              onClick={() => props.onBack()}
            >
              Back
            </Button>
          </div>
          <div className={styles.right}>
            <Button type="primary" size="default" htmlType="submit">
              Next
            </Button>
          </div>
        </div>
      </Form.Item>
    </Form>
  );
}
