import { Alert, Col, Grid, message, Row, Spin, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Deck } from "./Deck";
import { Execution } from "./Execution";
import { Intro } from "./Intro";
import { Investment } from "./Investment";
import { Potential } from "./Potential";
import { Report } from "./Report";
import styles from "./Screening.module.css";
import {
  createStartup,
  fetchStartup,
  fetchStartupReport,
  screeningSelector,
  updateStartup,
  updateStartupDoc,
} from "./screeningSlice";
import { Team } from "./Team";

const { Step } = Steps;

export function Screening() {
  const dispatch = useDispatch();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const { data, report, loading, errors } = useSelector(screeningSelector);
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    dispatch(fetchStartup());
  }, [dispatch]);

  useEffect(() => {
    if (data.stage === 1) {
      dispatch(fetchStartupReport(data.id));
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (errors && errors.length > 0) {
      message.error(errors[0]);
    }
  }, [errors]);

  useEffect(() => {
    if (data) {
      if (data?.info?.inv_stage) {
        setCurrentStep(5);
      } else if (data?.info?.rev_current) {
        setCurrentStep(4);
      } else if (data?.info?.approach) {
        setCurrentStep(3);
      } else if (data?.info?.founded) {
        setCurrentStep(2);
      } else if (data?.name) {
        setCurrentStep(1);
      }
    }
  }, [data]);

  const goBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const goNext = (values) => {
    values.info = Object.assign({}, data.info, values.info);
    console.log(values);
    if (data.id) {
      dispatch(updateStartup({ id: data.id, data: values }));
    } else {
      dispatch(createStartup(values));
    }
  };

  const uploadDoc = (doc, values) => {
    values.info = Object.assign({}, data.info, values.info);
    values["stage"] = 1;
    dispatch(updateStartupDoc({ id: data.id, data: doc }));
    dispatch(updateStartup({ id: data.id, data: values }));
  };

  const getAlert = () => {
    switch (currentStep) {
      case 1:
        return (
          <Alert
            message="Good start 👍&nbsp;&nbsp;In a few minutes you will be able to get your screening report."
            type="info"
            closable
            className={styles.reportAlertSm}
          />
        );
      case 3:
        return (
          <Alert
            message="Startup-O team will reach out to you in 24 hours with results on the next steps once you press submit. Keep going 👍"
            type="info"
            closable
            className={styles.reportAlertSm}
          />
        );
      case 4:
        return (
          <Alert
            message="Almost there. Looking forward to welcome you to the Startup-O community...only a step away..."
            type="info"
            closable
            className={styles.reportAlertSm}
          />
        );
      default:
        return null;
    }
  };

  const getContent = () => {
    switch (currentStep) {
      case 0:
        return <Intro onNext={goNext} onBack={goBack} data={data} />;
      case 1:
        return <Team onNext={goNext} onBack={goBack} data={data} />;
      case 2:
        return <Execution onNext={goNext} onBack={goBack} data={data} />;
      case 3:
        return <Potential onNext={goNext} onBack={goBack} data={data} />;
      case 4:
        return <Investment onNext={goNext} onBack={goBack} data={data} />;
      case 5:
        return <Deck onSubmit={uploadDoc} onBack={goBack} data={data} />;
      default:
        <></>;
    }
  };

  return (
    <Spin spinning={loading}>
      {data.stage !== 1 && (
        <div className={screens.lg ? "container" : "containerMobile"}>
          <div className={styles.section}>
            {screens.lg ? (
              <Row align="middle" className="mt-10">
                <Col span={6} className="text-right">
                  <Steps
                    current={currentStep}
                    direction="vertical"
                    size="small"
                  >
                    <Step title="Intro" />
                    <Step title="Team" />
                    <Step title="Execution" />
                    <Step title="Potential" />
                    <Step title="Investment" />
                    <Step title="Deck" />
                  </Steps>
                </Col>
                <Col span={18}>
                  {getAlert()}
                  <div className={`${styles.content} mt-20`}>
                    {getContent()}
                  </div>
                </Col>
              </Row>
            ) : (
              <Row align="middle" className="mt-10">
                <Col span={24}>
                  {getAlert()}
                  <div className={`${styles.content} mt-20`}>
                    {getContent()}
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </div>
      )}
      {data.stage === 1 && (
        <div className="container-md-t">
          <Report startup={data} data={report} />
        </div>
      )}
    </Spin>
  );
}
