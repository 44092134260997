import { Button, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { questions } from "./questions";
import styles from "./Screening.module.css";

const fullTimeArray = [
  "Team size of 50+",
  "Team size of 25+",
  "Team size of 10+",
  "We are a small team making a big impact.",
  "Founders with largely an outsourced team.",
];

const unfairArray = [
  "Very Strong Revenue traction/user growth in past 12 months.",
  "Our Product/Service being Unique has claims that are supported by Intellectual Property e.g. Patents",
  "I have access to the relevant networks to commercialise the product.",
  "The Management has a deep know how of the Industry.",
  "I have a Customer Base of Fortune 1000 companies on my client list.",
  "I have a patentable product but not patented yet.",
  "We have a captive anchor client or user base to help develop the product further.",
  "I have investors on board.",
  "Media Tractions - Recently got recognized on a global platform.",
  "Other",
];

const approachArray = [
  "Have thoroughly conducted market researches and launched a product after deep cosultations with Industry Experts.",
  "Derived the idea from a problem being faced by an industry giant.",
  "Entered an incubator/accelerator and launched our idea from there.",
  "There is clear gap in the market and our product/service can offer a unique solution.",
  "There is lot of money to be made in ths industry.",
];

export function Execution(props) {
  const [showOther, setShowOther] = useState(false);
  const [otherVal, setOtherVal] = useState("");

  useEffect(() => {
    if (props.data.info.unfair) {
      if (unfairArray.includes(props.data.info.unfair)) {
        if (props.data.info.unfair === "Other") {
          setShowOther("true");
        }
      } else {
        setShowOther("true");
        setOtherVal(props.data.info.unfair);
      }
    }
  }, [props.data.info.unfair]);

  const handleSubmit = (values) => {
    if (showOther) {
      values["unfair"] = otherVal.length > 0 ? otherVal : "Other";
    }
    props.onNext({
      info: values,
    });
  };

  const initOther = (data, val) => {
    if (val) {
      return data.includes(val) ? val : "Other";
    } else {
      return val;
    }
  };

  return (
    <Form
      layout="vertical"
      size="large"
      initialValues={{
        fl_time: props.data?.info?.fl_time,
        unfair: initOther(unfairArray, props.data?.info?.unfair),
        approach: props.data?.info?.approach,
      }}
      onFinish={handleSubmit}
    >
      <Form.Item
        name="fl_time"
        label={questions["fl_time"]}
        className="mt-30"
        rules={[
          {
            required: true,
            message: "This field cannot be left empty!",
          },
        ]}
      >
        <Select>
          {fullTimeArray.map((c) => (
            <Select.Option key={c}>{c}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="unfair"
        label={questions["unfair"]}
        className={`mt-30 ${showOther ? "mb-10i" : ""}`}
        rules={[
          {
            required: true,
            message: "This field cannot be left empty!",
          },
        ]}
      >
        <Select
          onSelect={(val) => {
            if (val !== "Other") {
              setShowOther(false);
              setOtherVal("");
            } else {
              setShowOther(true);
            }
          }}
        >
          {unfairArray.map((c) => (
            <Select.Option key={c}>{c}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      {showOther && (
        <div className="mb-25">
          <Input
            placeholder="Type your answer..."
            value={otherVal}
            onChange={(e) => setOtherVal(e.target.value)}
          />
        </div>
      )}

      <Form.Item
        name="approach"
        label={questions["approach"]}
        className="mt-30"
        rules={[
          {
            required: true,
            message: "This field cannot be left empty!",
          },
        ]}
      >
        <Select>
          {approachArray.map((c) => (
            <Select.Option key={c}>{c}</Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item className="mb-5">
        <div className={styles.buttons}>
          <div className={styles.left}>
            <Button
              type="default"
              size="default"
              onClick={() => props.onBack()}
            >
              Back
            </Button>
          </div>
          <div className={styles.right}>
            <Button type="primary" size="default" htmlType="submit">
              Next
            </Button>
          </div>
        </div>
      </Form.Item>
    </Form>
  );
}
