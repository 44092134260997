import { InboxOutlined } from "@ant-design/icons";
import { Button, Form, Input, Upload } from "antd";
import React, { useState } from "react";
import { questions } from "./questions";
import styles from "./Screening.module.css";

const { TextArea } = Input;

export function Deck(props) {
  const [fileList, setFileList] = useState([]);

  const handleSubmit = (values) => {
    var fd = new FormData();
    fd.append("pitch_deck", fileList[0]);
    props.onSubmit(fd, { info: { referral: values["referral"] } });
  };

  return (
    <Form layout="vertical" size="large" onFinish={handleSubmit}>
      <Form.Item
        name="pitch_deck"
        label="Upload your latest pitch deck"
        rules={[
          {
            required: true,
            message:
              "This field cannot be left empty! If you are unable to upload your pitch deck through this portal, please contact us at hello@startup-o.com.",
          },
        ]}
      >
        <Upload.Dragger
          name="pitch_deck"
          beforeUpload={(file) => {
            setFileList([file]);
            return false;
          }}
          onRemove={() => setFileList([])}
          maxCount={1}
          className={styles.pitch}
          fileList={fileList}
          accept=".ppt, .pptx, .pdf"
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload (pdf or ppt)
          </p>
        </Upload.Dragger>
      </Form.Item>

      <Form.Item
        name="referral"
        label={questions["referral"]}
        rules={[
          {
            required: true,
            message: "This field cannot be left empty!",
          },
        ]}
      >
        <TextArea
          rows={2}
          placeholder="Example: Person’s name, Institution, Google, LinkedIn, etc."
        />
      </Form.Item>

      <Form.Item className="mb-5">
        <div className={styles.buttons}>
          <div className={styles.left}>
            <Button
              type="default"
              size="default"
              onClick={() => props.onBack()}
            >
              Back
            </Button>
          </div>
          <div className={styles.right}>
            <Button type="primary" size="default" htmlType="submit">
              Submit
            </Button>
          </div>
        </div>
      </Form.Item>
    </Form>
  );
}
