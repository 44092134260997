import { Empty, Typography } from "antd";
import React from "react";
import logo from "./assets/img/logo.png";

const { Title } = Typography;

export function NotFound(params) {
  return (
    <div
      style={{
        background: "#168781",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ marginTop: "-200px" }}>
        <img src={logo} alt="logo" width="250" />
        <Empty
          style={{ marginTop: "50px" }}
          description={
            <Title level={3} style={{ color: "#fccc11" }}>
              Page not found
            </Title>
          }
        />
      </div>
    </div>
  );
}
